import { getAppDimension } from "./helpers/getAppDimension";
import { wvusLogger } from "./helpers/appLoggerV2";

(function (global) {
  if (!window.location.search) {
    return;
  }

  function getCookieVal(name, decodeVal = true) {
    if (!window.document || !window.document.cookie) {
      return "";
    }
    const reCookieName = window.document.cookie.match(
      new RegExp("(^| )" + name + "=([^;]+)")
    );
    const cookieVal = reCookieName ? reCookieName[2] : "";
  
    if (decodeVal === true) {
      return decodeURIComponent(cookieVal);
    }
  
    return cookieVal;
  }

  const params = (window.location.search.split("?")[1].split("&") || []).reduce(function (params, paramString) {
    const paramArr = paramString.split("=");

    if (paramArr[0] && paramArr[1]) {
      params[paramArr[0].toLowerCase()] = paramArr[1];
    }

    return params;
  }, {});

  const widgetId = params["widgetid"];
  const widgetEnv = params["widgetenv"] || "prod";
  const cssc = getCookieVal("campaign") || params["campaign"] || "";

  if (!widgetId) {
    // TODO: Message Warning To Parent window
    return;
  }

  global.wvusWidgetParams = {
    widgetId: widgetId,
    widgetEnv: widgetEnv,
    cssc: cssc,
  };

  // Create new script element
  const script = global.document.createElement("script");
  const sourceHost = ["qa", "qa1", "qa2", "uat", "dev", "local"].includes(widgetEnv)
    ? "https://donate-widget-preprod.worldvision.org"
    : "https://donate-widget.worldvision.org";
  script.src = sourceHost + "/configs/" + widgetEnv + "/widget." + widgetId + ".configs.js?nocache=" + Date.now();

  // Append to the `head` element
  global.document.head.appendChild(script);

  // On Widget Load This is For the Widget
  window.wvusloadWidget = {
    event: new Event("loadWidgetConfig"),
    handlers: [],
    data: null,
    eventFired: false,
    setData: function (data) {
      this.data = data;
    },
    onEventFire: function () {
      this.fireHandlers(this.data);
    },
    onLoadConfig: function (cb) {
      if (!this.eventFired) {
        this.handlers.push(cb);
      } else {
        try {
          cb(this.data);
        } catch (err) {
          wvusLogger.log(err, wvusLogger.getLogLevelNames().ERROR);
          console.log(err);
        }
      }
    },
    trigger: function (data) {
      if (!this.eventFired) {
        this.setData(data);
        window.dispatchEvent(this.event);
        this.eventFired = true;
      }
    },
    fireHandlers: function (data) {
      for (let i = 0; i < this.handlers.length; i++) {
        try {
          this.handlers[i](data);
        } catch (err) {
          wvusLogger.log(err, wvusLogger.getLogLevelNames().ERROR);
          console.error(err);
        }
      }
      this.handlers = [];
    },
  };

  window.addEventListener("loadWidgetConfig", window.wvusloadWidget.onEventFire.bind(window.wvusloadWidget));

  function parseMessageData(data) {
    try {
      return JSON.parse(data);
    } catch (err) {
      return {};
    }
  }

  script.addEventListener("error", function (event) {
    // Unable to load config script
    window.parent.postMessage(
      JSON.stringify({ status: "error", widgetId, message: "Unable to load config script" }),
      "*"
    );
  });

  script.addEventListener("load", function () {
    const widgetConfig = window.wvusDonateWidgetConfig[widgetId];
    const appSize = getAppDimension(widgetConfig);

    window.wvusloadWidget.trigger({ widgetConfig });

    window.parent.postMessage(JSON.stringify({ status: "load", appSize, widgetId }), "*");
    window.addEventListener("message", function (event) {
      try {
        const config = (window.wvusDonateWidgetConfig && window.wvusDonateWidgetConfig[widgetId]) || null;

        if (!config) {
          event.source.postMessage(
            JSON.stringify({ success: false, message: "unable to load config based on widgetid " + widgetId }),
            event.origin
          );
          return;
        }

        const postData = parseMessageData(event.data);

        if (postData.status === "info" && widgetId === postData.widgetId && widgetEnv === postData.widgetEnv) {
          // Set Window Variable Of Parent Window Info
          window.widgetParent = {
            origin: postData.parentOrigin || event.origin,
            parentUrl: postData.parentUrl || "",
            parentTitle: postData.parentTitle || "",
          };
        }

        const configOrigins = config.host.split(",");
        const isValidOrigin =
          event.origin.includes("worldvision") ||
          configOrigins.some(function (origin) {
            return (origin && origin.trim() === event.origin) || origin.includes(event.origin);
          });

        if (!isValidOrigin) {
          event.source.postMessage(JSON.stringify({ success: false, message: "unauthorized origin" }), event.origin);
        } else {
          event.source.postMessage(JSON.stringify({ success: true }), event.origin);
        }
      } catch (err) {
        event.source.postMessage(
          JSON.stringify({ success: false, message: "Error loading app: " + err.message }),
          event.origin
        );
      }
    });
  });
})(window);
